export const DEMO_LINK = "https://intelligahealth.typeform.com/inquiry";
export const HANDLE_REQUEST_DEMO = () => (window.location.href = DEMO_LINK);
export const HIRING_LINK = "https://intelliga-health-careers.super.site/";
export const HANDLE_HIRING = () => (window.location.href = HIRING_LINK);
export const LOGIN_LINK = "https://app.intelligahealth.com";
export const HANDLE_LOGIN = () => (window.location.href = LOGIN_LINK);
export const BLOG_LINK = "https://blog.intelligahealth.com";
export const HANDLE_BLOG = () => (window.location.href = BLOG_LINK);
export const APPEALS_LINK = "https://appeals.intelligahealth.com";
export const HANDLE_APPEALS_PORTAL = () =>
  (window.location.href = APPEALS_LINK);
