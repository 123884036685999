import React from "react";
import { Heading } from "../../../Components/Heading";

const Contact = () => {
  return (
    <div
      id="contact"
      className="bg-background1 w-full flex flex-col items-start px-mobilePadding sm:px-sectionPaddingSmall py-sectionPaddingTop lg:py-sectionPaddingTopLarge lg:px-sectionPaddingLarge"
    >
      <Heading>Let's talk</Heading>
      <p className="text-2xl mt-8">
        Interested in automating your prior authorization appeals? We're here to
        help.
      </p>
      <p className="text-2xl mt-8">
        Email us at{" "}
        <a
          href="mailto:hello@intelligahealth.com?subject=Hello!"
          className="underline transition-all hover:opacity-70"
        >
          hello@intelligahealth.com
        </a>
      </p>
    </div>
  );
};

export default Contact;
