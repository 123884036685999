import React from "react";

const Banner = () => {
  return (
    <div className="w-full bg-background1 flex flex-col items-center justify-center py-sectionPaddingTop px-mobilePadding sm:px-sectionPaddingSmall">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-2xl font-medium text-olive px-4">Backed by</h2>
      </div>

      <img src="yc.svg" className="w-64" />
    </div>
  );
};

export default Banner;
