import React from "react";
import { Button } from "../../../Components/Button";
import { Logo } from "../../../Components/Logo";
import { HANDLE_REQUEST_DEMO } from "../../../constants";
import { BsArrowRight } from "react-icons/bs";

const Footer = () => {
  return (
    <div className="w-full flex justify-between items-center py-sectionPaddingTopSmall px-mobilePadding sm:px-sectionPaddingSmall bg-white">
      <Logo />

      <div className="hidden sm:block">
        <Button variant="faded" onClick={HANDLE_REQUEST_DEMO}>
          Get started <BsArrowRight className="ml-2" />
        </Button>
      </div>
    </div>
  );
};

export default Footer;
