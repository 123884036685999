import React from "react";
import { Button } from "../../../Components/Button";
import { Heading } from "../../../Components/Heading";
import { BsArrowRight } from "react-icons/bs";
import { HANDLE_REQUEST_DEMO } from "../../../constants";

const Security = () => {
  return (
    <div
      id="security"
      className="bg-white w-full flex flex-col items-center px-mobilePadding sm:px-sectionPaddingSmall py-sectionPaddingTop lg:py-sectionPaddingTopLarge lg:px-sectionPaddingLarge"
    >
      <Heading center>HIPAA Compliance</Heading>
      <p className="text-olive text-2xl my-8 text-center">
        Your patients' and organization's security is our top priority. We use
        Vanta to monitor our technical infrastructure and follow best practices
        to maintain compliance.
      </p>
      <img
        src="Vanta.svg"
        alt="Vanta logo"
        title="Vanta logo"
        className="mb-8"
      />
      <Button variant="transparent" onClick={HANDLE_REQUEST_DEMO}>
        Learn more <BsArrowRight className="ml-2" />
      </Button>
    </div>
  );
};

export default Security;
