import React from "react";

const buttonStyles = {
  inline: "text-primary text-base hover:underline",
  outline:
    "flex items-center text-primary text-base border border-primary rounded py-2.5 px-4 hover:bg-primary hover:text-white justify-center transition-all",
  outlineRed:
    "flex items-center text-red text-base border border-red rounded py-2.5 px-4 hover:bg-red hover:text-white justify-center transition-all",
  full: "flex items-center text-base rounded-full py-2.5 px-6 bg-navyBlue text-white justify-center transition-all hover:opacity-90 shadow-md",
  transparent:
    "flex items-center text-base rounded-full py-2.5 px-12 bg-transparent text-navyBlue border border-navyBlue rounded-full justify-center transition-all hover:bg-navyBlue hover:text-white",
  "outline-white":
    "flex items-center text-base rounded-full py-2.5 px-12 bg-transparent text-white border border-white rounded-full justify-center transition-all hover:bg-white hover:text-navyBlue",
  faded:
    "flex items-center text-base rounded-full py-2.5 px-12 bg-transparent text-[navyBlue/75] border border-[navyBlue/75] rounded-full justify-center transition-all hover:bg-navyBlue hover:text-white",
  fadedSmall:
    "flex items-center text-base rounded-full py-2.5 px-6 bg-transparent text-[navyBlue/75] border border-[navyBlue/75] rounded-full justify-center transition-all hover:bg-navyBlue hover:text-white",
};

export const Button = ({
  variant,
  children,
  onClick,
  fullWidth,
  type = "button",
  form = "",
  ref = null,
  disabled = false,
  onFileUpload = null,
}) => {
  if (onFileUpload) {
    return (
      <label
        for="dropzone-file"
        className={`${buttonStyles[variant]} ${
          fullWidth && "w-full"
        } cursor-pointer`}
      >
        {children}
        <input
          id="dropzone-file"
          onChange={onFileUpload}
          accept="application/pdf"
          type="file"
          className="hidden"
        />
      </label>
    );
  }

  return (
    <button
      onClick={onClick}
      className={`${buttonStyles[variant]} ${fullWidth && "w-full"}`}
      type={type}
      form={form}
      ref={ref}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
