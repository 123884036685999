import React, { useState } from "react";
import Navbar from "../../Components/Navbar";
import Banner from "./Sections/Banner";
import Security from "./Sections/Security";
import Contact from "./Sections/Contact";
import Footer from "./Sections/Footer";
import Hero from "./Sections/Hero";
import HowItWorks from "./Sections/HowItWorks";
import Mission from "./Sections/Mission";
import FAQs from "./Sections/FAQs";
import Modal from "../../Components/Modal";
import { InlineWidget } from "react-calendly";

const HomePage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="max-w-maxPageWidth flex flex-col mx-auto items-center">
      <Navbar requestDemo={toggle} />
      <Hero requestDemo={toggle} />
      <Banner />
      <HowItWorks requestDemo={toggle} />
      <Mission requestDemo={toggle} />
      <Contact />
      <Security requestDemo={toggle} />
      <FAQs />
      <Footer requestDemo={toggle} />

      <Modal isOpen={isOpen} close={toggle}>
        <div className="flex flex-col items-center justify-between p-8 w-letterWidth">
          <h2 className="text-2xl font-bold">Book a demo</h2>
          <p className="text-center my-2">
            See the full Intelliga Health platform in action.
          </p>
          <InlineWidget
            url="https://calendly.com/intelliga/intelliga-health-demo"
            styles={{
              width: "100%",
              height: "500px",
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default HomePage;
