import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../Components/Button";

const PageNotFound = () => {
  const navigate = useNavigate();
  const handleReturnHome = () => navigate("/");

  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center">
      <h1 className="font-lora text-6xl mb-8">Page not found</h1>
      <Button variant="transparent" onClick={handleReturnHome}>
        Return home
      </Button>
    </div>
  );
};

export default PageNotFound;
