import React, { useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Button } from "./Button";
import { Logo } from "./Logo";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  HANDLE_REQUEST_DEMO,
  HIRING_LINK,
  HANDLE_APPEALS_PORTAL,
} from "../constants";
import { BsArrowRight } from "react-icons/bs";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  return (
    <div className="w-full xl:max-w-maxPageWidth mx-auto flex flex-col xl:flex-row justify-between items-center py-sectionPaddingTopSmall px-mobilePadding sm:px-sectionPaddingSmall fixed top-0 backdrop-blur-lg bg-white/90">
      <div className="w-full xl:w-auto flex justify-between">
        <Logo />
        {open ? (
          <XMarkIcon
            className="block xl:hidden h-6 w-6 cursor-pointer"
            aria-hidden="true"
            onClick={toggleOpen}
          />
        ) : (
          <Bars3Icon
            className="block xl:hidden h-6 w-6 cursor-pointer"
            aria-hidden="true"
            onClick={toggleOpen}
          />
        )}
      </div>

      <div
        className={`${
          open ? "flex flex-col mt-4 self-start" : "hidden"
        } xl:flex xl:flex-row items-start xl:self-center xl:items-center xl:mt-0`}
      >
        <NavbarLink href="#howitworks">How it works</NavbarLink>
        <NavbarLink href="#mission">Our mission</NavbarLink>
        <NavbarLink href="#contact">Contact</NavbarLink>
        <NavbarLink href="#security">Security</NavbarLink>
        <a className="mr-6 mb-4 xl:mb-0 hover:underline" href={HIRING_LINK}>
          Careers
        </a>
        <NavbarLink onClick={HANDLE_APPEALS_PORTAL}>Appeal suite</NavbarLink>
        <Button variant="full" onClick={HANDLE_REQUEST_DEMO}>
          Get in touch <BsArrowRight className="ml-2" />
        </Button>
      </div>
    </div>
  );
};

const NavbarLink = ({ href, children, toggleOpen, onClick }) => {
  if (onClick) {
    return (
      <div
        className="mr-6 mb-4 xl:mb-0 hover:underline cursor-pointer"
        onClick={onClick}
      >
        {children}
      </div>
    );
  }

  return (
    <div className="mr-6 mb-4 xl:mb-0 hover:underline" onClick={toggleOpen}>
      <AnchorLink href={href}>{children}</AnchorLink>
    </div>
  );
};

export default Navbar;
