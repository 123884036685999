import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { Button } from "../../../Components/Button";
import { Heading } from "../../../Components/Heading";

const HowItWorks = ({ requestDemo }) => {
  return (
    <div
      id="howitworks"
      className="flex flex-col items-center py-sectionPaddingTopLarge px-mobilePadding sm:px-sectionPaddingSmall lg:px-sectionPaddingLarge w-full"
    >
      <Heading>How it works</Heading>

      <HowItWorksSection reverse>
        <HowItWorksImage src="fax.svg" alt="Fax machine" />
        <HowItWorksText
          step={1}
          title="Connect your fax"
          description="We provide you with a dedicated, secure fax number or connect to your existing machine in just 30 seconds."
          requestDemo={requestDemo}
        />
      </HowItWorksSection>

      <HowItWorksSection>
        <HowItWorksText
          step={2}
          title="Smart appeals"
          description="Our AI instantly generates a customized appeal letter for your patient's claim based on proven winning appeals."
          requestDemo={requestDemo}
        />
        <HowItWorksImage
          src="AIAppeals.svg"
          alt="Diagram of artifiicial intelligence creating prior authorization letter"
        />
      </HowItWorksSection>

      <HowItWorksSection reverse>
        <HowItWorksImage
          src="InsuranceSubmit.svg"
          alt="BUtton with a hovered cursor showing insurance destinations"
        />
        <HowItWorksText
          step={3}
          title="Streamlined submission"
          description="Edit the letter as needed and submit it to the insurance company – all in under 1 minute per appeal."
          requestDemo={requestDemo}
        />
      </HowItWorksSection>
    </div>
  );
};

const HowItWorksImage = ({ src, alt }) => (
  <img src={src} alt={alt} className="w-full lg:w-2/5" title={alt} />
);

const HowItWorksSection = ({ children, reverse = false }) => (
  <div
    className={`w-full flex ${
      reverse ? "flex-col-reverse" : "flex-col"
    } lg:flex-row justify-between mt-16 lg:mt-32 items-start`}
  >
    {children}
  </div>
);

const HowItWorksText = ({ step, title, description, requestDemo }) => {
  return (
    <TextContainer>
      <Step>Step {step}</Step>
      <SubHeading>{title}</SubHeading>
      <Description>{description}</Description>
      <div className="mt-6">
        <Button variant="transparent" onClick={requestDemo}>
          Book a demo <BsArrowRight className="ml-2" />
        </Button>
      </div>
    </TextContainer>
  );
};

const SubHeading = ({ children }) => (
  <h2 className="font-lora text-2xl lg:text-4xl">{children}</h2>
);

const Step = ({ children }) => (
  <p className="px-6 py-2 bg-white rounded-full border border-px border-background2 mb-8">
    {children}
  </p>
);

const TextContainer = ({ children }) => (
  <div className="w-full lg:w-2/5 mb-16 lg:mb-0 flex flex-col items-start px-8 py-8 sm:py-sectionPaddingTop bg-background1 rounded-lg lg:hover:shadow-xl delay-[0ms] ease-linear transition-all">
    {children}
  </div>
);

const Description = ({ children }) => (
  <p className="text-lg text-olive mt-2.5">{children}</p>
);

export default HowItWorks;
