import React from "react";
import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import { Heading } from "../../../Components/Heading";

const faqs = [
  {
    question: "How does Intelliga Health's AI-driven solution work?",
    answer:
      "Intelliga Health's AI-driven solution analyzes successful prior authorization appeals and generates customized appeal letters for healthcare providers. By streamlining the process, it saves time and increases the chances of a successful appeal, ultimately helping providers secure necessary treatments for their patients.",
  },
  {
    question: "Is Intelliga Health's solution HIPAA compliant?",
    answer:
      "Intelliga Health uses Vanta to monitor its infrastructure and set a high bar for compliance. We prioritize the security and confidentiality of your patients' and organization's data and we adhere to industry best practices.",
  },
  {
    question:
      "How much time can I save using Intelliga Health's prior authorization appeal solution?",
    answer:
      "With Intelliga Health's AI-driven solution, you can save up to 10 hours per week on prior authorization appeals. Our platform streamlines the process, generating customized appeal letters and allowing for quick revisions and submissions.",
  },
  {
    question: "Can I edit the AI-generated appeal letter?",
    answer:
      "Yes, you can edit the AI-generated appeal letter to your liking before submitting it to the insurance company. Our solution is designed to provide you with a strong foundation for your appeal, which can be customized further to meet your specific needs.",
  },
  {
    question: "How do I get started with Intelliga Health?",
    answer:
      "To get started with Intelliga Health, simply request a demo through our website. Our team will guide you through the setup process, including connecting your fax machine and providing you with a dedicated, secure fax number. Once set up, you can begin using our AI-driven solution to streamline your prior authorization appeals.",
  },
];

const FAQs = () => {
  return (
    <div
      id="faqs"
      className="w-full bg-background1 flex flex-col lg:flex-row py-sectionPaddingTop lg:py-sectionPaddingTopLarge px-mobilePadding sm:px-sectionPaddingSmall lg:px-sectionPaddingLarge flex items-center justify-between"
    >
      <div className="w-full divide-y divide-gray-900/10">
        <Heading>Frequently asked questions</Heading>
        <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
          {faqs.map((faq) => (
            <Disclosure as="div" key={faq.question} className="pt-6">
              {({ open }) => (
                <>
                  <dt>
                    <Disclosure.Button className="flex w-full items-start justify-between text-left">
                      <p className="text-lg sm:text-2xl">{faq.question}</p>

                      <span className="ml-6 flex h-7 items-center">
                        {open ? (
                          <MinusSmallIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                          />
                        ) : (
                          <PlusSmallIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                          />
                        )}
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2 pr-12">
                    <p className="text-olive text-base sm:text-xl">
                      {faq.answer}
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default FAQs;
