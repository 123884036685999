import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { Button } from "../../../Components/Button";
import { Heading } from "../../../Components/Heading";
import { HANDLE_REQUEST_DEMO } from "../../../constants";
import { LazyLoadComponent } from "react-lazy-load-image-component";

const Mission = () => {
  return (
    <div
      id="mission"
      className="w-full bg-navyBlue flex flex-col lg:flex-row py-sectionPaddingTop lg:py-sectionPaddingTopLarge px-mobilePadding sm:px-sectionPaddingSmall lg:px-sectionPaddingLarge flex items-center justify-between"
    >
      <div className="w-full mb-16 lg:mb-0 lg:w-1/2 lg:px-sectionPaddingSmall flex flex-col items-start">
        <Heading color="white">Our mission</Heading>
        <h4 className="text-white mt-8 text-2xl font-bold">
          Empowering Providers to Put Patients First.
        </h4>
        <p className="text-white text-xl mt-2">
          Every day, healthcare providers face the question: "Is it worth
          prescribing this medication, knowing insurance might deny it?"
          Insurance companies rely on this doubt to save money. We're here to
          change the game – to make insurance companies pay for the treatments
          patients truly need.
          <br />
          <br />
          We help providers streamline prior authorization appeals with our
          AI-driven solution.
        </p>
        <div className="mt-12">
          <Button variant="outline-white" onClick={HANDLE_REQUEST_DEMO}>
            Learn more <BsArrowRight className="ml-2" />
          </Button>
        </div>
      </div>
      <div className="w-full lg:w-1/2 lg:px-sectionPaddingSmall flex flex-col items-start">
        <LazyLoadComponent>
          <iframe
            loading="lazy"
            src="https://www.youtube.com/embed/zGKtROmiJL8"
            title="How Health Insurance Companies Make Money"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            className="w-full h-96"
          />
        </LazyLoadComponent>
      </div>

      {/* <iframe
        className="w-full h-96"
        src="https://www.youtube.com/embed/zGKtROmiJL8"
        srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/zGKtROmiJL8><img src=https://img.youtube.com/vi/Y8Wp3dafaMQ/hqdefault.jpg alt='Video The Dark Knight Rises: What Went Wrong? – Wisecrack Edition'><span>▶</span></a>"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        title="How Health Insurance Companies Make Money"
      ></iframe> */}
    </div>
  );
};

export default Mission;
