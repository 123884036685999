import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { Button } from "../../../Components/Button";
import { Heading } from "../../../Components/Heading";
import { HANDLE_LOGIN, HANDLE_REQUEST_DEMO } from "../../../constants";

const Hero = () => {
  return (
    <div className="w-full mt-36 lg:mt-12 px-mobilePadding sm:px-sectionPaddingSmall flex flex-col lg:flex-row justify-between lg:h-[82vh] items-center">
      <div className="flex flex-col w-full lg:w-2/5 items-start">
        <Heading as="h1">Effortless prior authorization appeals</Heading>

        <p className="mt-6 mb-8 text-2xl">
          Appeal <span className="font-italic font-bold">97% quicker</span>
        </p>

        <div className="flex flex-col sm:flex-row">
          <Button variant="full" onClick={HANDLE_REQUEST_DEMO}>
            Get in touch <BsArrowRight className="ml-2" />
          </Button>
          <div className="mt-4 sm:mt-0 sm:ml-4">
            <Button
              variant="fadedSmall"
              onClick={(HANDLE_REQUEST_DEMO, HANDLE_LOGIN)}
            >
              Login <BsArrowRight className="ml-2" />
            </Button>
          </div>
        </div>
      </div>
      <div className="w-full my-16 lg:my-0 lg:w-3/5">
        <img
          src="landingpage.svg"
          alt="Smiling doctor"
          className="w-full"
          title="Smiling doctor"
        />
      </div>
    </div>
  );
};

export default Hero;
