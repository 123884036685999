import React from "react";
import { useNavigate } from "react-router-dom";

export const Logo = () => {
  const navigate = useNavigate();

  return (
    <h3
      className="text-xl text-primary cursor-pointer"
      onClick={() => navigate("/")}
    >
      <span className="font-bold">INTELLIGA</span> HEALTH
    </h3>
  );
};
