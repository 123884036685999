import React from "react";

export const Heading = ({
  children,
  center = false,
  color = "navyBlue",
  as = "h2",
}) => {
  if (as === "h2") {
    return (
      <h2
        className={`font-lora text-4xl lg:text-6xl text-${color} ${
          center && "text-center"
        }`}
      >
        {children}
      </h2>
    );
  }

  return (
    <h1
      className={`font-lora text-4xl lg:text-6xl text-${color} ${
        center && "text-center"
      }`}
    >
      {children}
    </h1>
  );
};
